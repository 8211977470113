<script setup>

import {computed, onMounted, reactive, ref} from "vue";

let bootstrap = require('bootstrap');

const props = defineProps({
    iframe_src_url: {
        type: String,
        required: true
    },
    account_route: {
        type: String,
        required: true
    },
});
const textFullScreen = 'На весь экран';
const textDecrease = 'Уменьшить';

const state = reactive({
    is_fullscreen: false,
});

const iframeRef = ref(null);
const fsBtn = ref(null);

const fs_button_title = computed(() => {
    return state.is_fullscreen ? textDecrease : textFullScreen;
})

function makeFullscreen() {
    bootstrap.Tooltip.getInstance(fsBtn.value).dispose();

    if (state.is_fullscreen) {
        document.body.classList.remove('fullscreen_on');
        document.body.scrollIntoView(true);
    } else {
        document.body.classList.add('fullscreen_on');
        iframeRef.value.scrollIntoView(true);
    }
    state.is_fullscreen = !state.is_fullscreen;

    bootstrap.Tooltip.getOrCreateInstance(fsBtn.value, {
        'title': fs_button_title.value,
        'placement': 'bottom'
    });
}

onMounted(() => {
    bootstrap.Tooltip.getOrCreateInstance(fsBtn.value, {
        'title': fs_button_title.value,
        'placement': 'bottom'
    });
})
</script>

<template>
    <div class="hide-google-menu"></div>
    <div class="position-relative" style="padding-top: 8em">
        <div class="block-editor-menu-container">
            <button
                @click="makeFullscreen"
                ref="fsBtn"
                id="fullscreen-button"
                class="button button_block-editor button_go-fullscreen button_go-fullscreen-on">
                    <span v-if="!state.is_fullscreen">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z"/>
                        </svg>
                    </span>
                <span v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-arrows-angle-contract" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M.172 15.828a.5.5 0 0 0 .707 0l4.096-4.096V14.5a.5.5 0 1 0 1 0v-3.975a.5.5 0 0 0-.5-.5H1.5a.5.5 0 0 0 0 1h2.768L.172 15.121a.5.5 0 0 0 0 .707zM15.828.172a.5.5 0 0 0-.707 0l-4.096 4.096V1.5a.5.5 0 1 0-1 0v3.975a.5.5 0 0 0 .5.5H14.5a.5.5 0 0 0 0-1h-2.768L15.828.879a.5.5 0 0 0 0-.707z"/>
                        </svg>
                    </span>
            </button>

            <a :href="account_route"
               class="button button_block-editor button_go-fullscreen button_go-fullscreen-on"
               data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-html="true"
               title="Сохранить на потом">
                    <span>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                             xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20"
                             viewBox="0 0 16 16" fill="currentColor">
                            <g>
                                <path d="M3.6,14.5c-0.5,0-1.1,0-1.6,0c0,0,0,0,0,0c-0.5-0.1-1-0.4-1.5-0.9c0,0,0,0,0,0c-0.1-0.2-0.3-0.5-0.4-1c0,0,0,0,0,0
                                    c0-0.8,0-1.7-0.1-2.5c0-0.4,0-0.8,0-1.3c0,0,0,0,0,0c0-0.8,0-1.7,0-2.5c0-0.5,0-0.9,0.1-1.5c0,0,0,0,0-0.1c0.1-0.2,0.1-0.3,0.2-0.4
                                    c0,0,0,0,0-0.1c0,0,0-0.1,0.1-0.1C1.8,2.6,3,1.5,4.1,0.5c0,0,0,0,0,0l0,0c0.1-0.1,0.4-0.3,0.7-0.3C6.6,0,8.3,0,9.9,0
                                    c0.7,0,1.5,0,2.2,0c0,0,0,0,0,0c0.3,0,0.5,0.1,0.7,0.1c0,0,0,0,0,0c0.8,0.3,1.4,0.9,1.6,1.7c0,0,0,0.1,0,0.1c0,1.4,0,2.8,0,4.2
                                    l0,0.4c0,0.3-0.3,0.6-0.6,0.6c0,0,0,0,0,0c-0.3,0-0.6-0.3-0.6-0.6l0-0.4c0-1.4,0-2.7,0-4.1c-0.1-0.3-0.4-0.6-0.7-0.7
                                    c-0.1,0-0.3-0.1-0.4-0.1c-0.7,0-1.5,0-2.2,0c-1.6,0-3.3,0-4.9,0.1c0,0,0,0-0.1,0l0,0c-1.1,1-2.2,2.1-3.5,3.4c0,0,0,0,0,0
                                    C1.4,5,1.4,5,1.4,5C1.3,5.4,1.3,5.8,1.3,6.2c0,0,0,0,0,0c0,0.8,0,1.6,0,2.4c0,0.4,0,0.9,0,1.3c0,0.8,0,1.7,0.1,2.5
                                    c0,0.1,0,0.2,0.1,0.3c0.2,0.3,0.4,0.4,0.6,0.5c0.9,0.1,1.9,0,2.8,0c0.6,0,1.1,0,1.7,0c0.3,0,0.6,0.3,0.6,0.6c0,0.3-0.3,0.6-0.6,0.6
                                    c-0.5,0-1.1,0-1.7,0C4.5,14.5,4,14.5,3.6,14.5z M5,1.4C5,1.4,5,1.4,5,1.4z"/>
                            </g>
                            <g>
                                <path d="M4.8,14.4c-0.3,0-0.6-0.3-0.6-0.6V9.7c0-0.3,0.3-0.6,0.6-0.6h1.7c0.3,0,0.6,0.3,0.6,0.6c0,0.3-0.3,0.6-0.6,0.6H5.4v3.5
                                    C5.4,14.2,5.1,14.4,4.8,14.4z"/>
                            </g>
                            <g>
                                <path d="M11.7,16c-2.4,0-4.3-1.9-4.3-4.3s1.9-4.3,4.3-4.3S16,9.3,16,11.7S14.1,16,11.7,16z M11.7,8.6c-1.7,0-3.1,1.4-3.1,3.1
                                    s1.4,3.1,3.1,3.1s3.1-1.4,3.1-3.1S13.4,8.6,11.7,8.6z"/>
                            </g>
                            <g>
                                <path d="M12.4,13c-0.2,0-0.3-0.1-0.5-0.2L11.2,12c-0.1-0.1-0.2-0.3-0.2-0.4v-1.4c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6v1.2
                                    l0.6,0.7c0.2,0.3,0.2,0.6-0.1,0.9C12.7,13,12.6,13,12.4,13z"/>
                            </g>
                        </svg>
                    </span>
            </a>

            <slot name="submit-block"/>
        </div>
        <iframe id="google_doc_iframe"
                name="embed_readwrite"
                ref="iframeRef"
                :src="iframe_src_url"
                width="100%"
                height="600"
                frameborder="0"
                style="height: 100vh;">
        </iframe>
    </div>
</template>

<style>
.hide-google-menu {
    position: relative;
    top: 27px;
    left: 0;

    width: 100%;
    height: 33px;

    background-color: #fff;
    z-index: 999;
}

body.fullscreen_on {
    overflow: hidden;
}

body.fullscreen_on header {
    display: none !important;
}
</style>
