import {createApp} from "vue";
import Editor from "./vue/components/Editor.vue";

const vueApp  = createApp({});
vueApp.component('block-editor', Editor);
vueApp.mount("#vue-app");

require('./bootstrap');

let bootstrap = require('bootstrap');
import Starry from 'starry-rating';
import Glide from '@glidejs/glide';
import Splide from '@splidejs/splide';
import lozad from 'lozad';
import {FeedbackModal} from './components/feedback-modal';
import Cookies from 'js-cookie'

window.bootstrap = bootstrap;

const feedbackModal = new FeedbackModal();


const via = new URL(location.href).searchParams.get('via');
const clickId = new URL(location.href).searchParams.get('clickid');

if (via) {
    Cookies.set('editus_affiliate', via, {
        expires: 30,
        sameSite: 'lax',
    });
}

if (clickId) {
    Cookies.set('editus_affiliate_click_id', clickId, {
        expires: 30,
        sameSite: 'lax',
    });
}

window.onload = function() {
    let jivoBtns = document.querySelectorAll('.jivo__btn');

    if (jivoBtns.length > 0) {
        jivoBtns.forEach(btn => {
            btn.onclick = function () {
                jivo_api.open();
                yaCounter1838947.reachGoal('jivosite');
                return true;
            }
        });
    }
}

document.addEventListener('DOMContentLoaded', function () {
    const csrfToken = document.querySelector("[name='csrf-token']").getAttribute('content');

    let headerCartLink = document.querySelector('.header__cart');
    let buyBtns = document.querySelectorAll('.book-card__buy-btn');
    const editusToast = document.querySelector('.editus-toast');
    const newToast = new bootstrap.Toast(editusToast);

    if (buyBtns.length > 0 && headerCartLink) {
        buyBtns.forEach(btn => {
            btn.onclick = async function () {
                const formData = new FormData();
                formData.append('_token', csrfToken);
                formData.append('book_id', btn.dataset.bookId);

                try {
                    const response = await fetch('/add-to-cart', {
                        method: 'POST',
                        body: formData
                    });
                    const result = await response.json();

                    if (result.result === "success") {
                        headerCartLink.querySelector('span').innerText = result.qty;
                        headerCartLink.querySelector('span').classList.remove('d-none');

                        newToast.show();
                    } else if (result.result === "error") {
                        alert('Ошибка при добавлении товара в корзину');
                    }
                } catch (error) {
                    console.error('Ошибка:', error);
                }
            };
        });
    }

    let removeItemFromCartBtns = document.querySelectorAll('.checkout-item__remove-btn');

    if (removeItemFromCartBtns.length > 0) {
        removeItemFromCartBtns.forEach((btn) => {
            btn.onclick = async function () {
                try {
                    const id = btn.dataset.itemId;
                    const response = await fetch('/ajax/remove-item-from-cart/' + id, {
                        method: 'GET',
                    });
                    const result = await response.json();

                    if (result.status) {
                        if (result.count === 0) {
                            document.querySelector('.empty-cart-text ~ .row').remove();
                            document.querySelector('.empty-cart-text').classList.remove('d-none');
                        } else {
                            btn.closest('.checkout-item').remove();
                        }
                        headerCartLink.querySelector('span').innerText = result.count;
                    }
                } catch (error) {
                    console.error('Ошибка:', error);
                }
            }
        });
    }
    let checkoutForm = document.getElementById('checkout-form');

    if (checkoutForm) {
        let checkoutFormCheckBox = checkoutForm.querySelector('.checkout-form__offer-agreement');

        if (checkoutFormCheckBox) {
            checkoutFormCheckBox.onchange = function () {
                let submitBtn = checkoutForm.querySelector('.checkout-form__submit-btn');

                if (checkoutFormCheckBox.checked) {
                    submitBtn.classList.remove('checkout-form__submit-btn_disabled');
                } else {
                    submitBtn.classList.add('checkout-form__submit-btn_disabled');
                }
            }
        }

        checkoutForm.addEventListener('submit', async function (evt) {
            evt.preventDefault();

            if (!checkoutForm.checkValidity()) {
                checkoutForm.classList.add('was-validated')
                return;
            }
            checkoutForm.submit();
        });
    }

    let bookCommentForm = document.getElementById('book-comment-form');

    if (bookCommentForm) {
        bookCommentForm.addEventListener('submit', function (event) {
            event.preventDefault();
            let url = bookCommentForm.getAttribute('action');
            let recaptchaPublicKey = bookCommentForm.dataset.grecaptchaPublicKey;
            // Здесь валидация формы
            if (!bookCommentForm.checkValidity()) {
                bookCommentForm.classList.add('was-validated');
                return;
            }
            grecaptcha.ready(function () {
                grecaptcha.execute(recaptchaPublicKey, {action: 'submit'}).then(async function (token) {
                    document.querySelector("input[name='token']").value = token;
                    bookCommentForm.submit();
                });
            });
        });
    }

    let starRatingEl = document.getElementById('star-rating');

    if (starRatingEl) {
        let starRating = new Starry(starRatingEl, {
            onRate: function (rating) {
                document.querySelector("input[name='rating']").value = rating;
            },
            icons: {
                // File path, uri or base64 string for `src` attribute
                blank: '/img/icons/stars/blank.svg',
                hover: '/img/icons/stars/active.svg',
                active: '/img/icons/stars/active.svg'
            }
        });
    }

    let burgerMenu = document.querySelector('.header__burger');

    if (burgerMenu) {
        burgerMenu.addEventListener('click', function (evt) {
            let mobileMenu = document.querySelector('.header__mobile-menu');

            mobileMenu.classList.toggle('header__mobile-menu_show')
        });
    }

    let authorsBooksSlider = document.querySelector('.authors-books-slider');

    if (authorsBooksSlider) {
        new Glide(authorsBooksSlider, {
            type: 'slider',
            startAt: 0,
            perView: 3,
            breakpoints: {
                1200: {
                    perView: 3
                },
                960: {
                    perView: 3
                },
                600: {
                    perView: 2
                },
                450: {
                    perView: 1
                }
            }
        }).mount()
    }


    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    if (document.querySelector('.splide-cover-design')) {
        new Splide( '.splide-cover-design', {
            type: 'loop',
            pagination: false,
            perPage: 4,
            gap: "1em",
            breakpoints: {
                1440: {
                    gap: "1em",
                    perPage: 4,
                },
                1199: {
                    gap: "1em",
                    perPage: 3,
                },
                991: {
                    gap: "1em",
                    perPage: 2,
                },
                640: {
                    gap: 0,
                    perPage: 1,
                },
            }
        }).mount();
    }

    if (document.querySelector('.splide-publish')) {
        new Splide( '.splide-publish', {
            type: 'loop',
            pagination: false,
            perPage: 1,
            // breakpoints: {
            //     1440: {
            //         gap: "1em",
            //         perPage: 4,
            //     },
            //     1199: {
            //         gap: "1em",
            //         perPage: 3,
            //     },
            //     991: {
            //         gap: "1em",
            //         perPage: 2,
            //     },
            //     640: {
            //         gap: 0,
            //         perPage: 1,
            //     },
            // }
        }).mount();
    }

    if (document.querySelector('.splide-reviews')) {
        new Splide( '.splide-reviews', {
            type: 'loop',
            pagination: false,
        }).mount();
    }

    if (document.querySelector('.splide-author-books')) {
        new Splide( '.splide-author-books', {
            perPage: 4,
            gap: "1em",
            pagination: false,
            breakpoints: {
                1024: {
                    perPage: 3,
                },
                640: {
                    perPage: 1,
                },
            }
        }).mount();
    }


    let searchArticleInput = document.querySelector('.editus-search__articles-input');

    if (searchArticleInput) {
        searchArticleInput.oninput = async function () {
            if (searchArticleInput.value.length >= 3) {
                try {
                    const response = await fetch('/ajax/search-article?needle=' + searchArticleInput.value, {
                        method: 'GET',
                    });
                    document.querySelector('.editus-search-results').innerHTML = await response.text();
                    document.querySelector('.editus-search__input-wrap').classList.add('editus-search__input-wrap_show');
                } catch (error) {
                    console.error('Ошибка:', error);
                }
            }
        };

        window.addEventListener('click', function(e){
            if (document.querySelector('.editus-search__input').contains(e.target)){
                // Clicked in search input
                document.querySelector('.editus-search__input-wrap').classList.add('editus-search__input-wrap_show');
            } else{
                // Clicked outside search input
                document.querySelector('.editus-search__input-wrap').classList.remove('editus-search__input-wrap_show');
            }
        });
    }

    let searchInput = document.querySelector('.search-books-authors__input');

    if (searchInput) {
        searchInput.oninput = async function () {
            if (searchInput.value.length >= 3) {
                try {
                    const response = await fetch('/ajax/search-book?needle=' + searchInput.value, {
                        method: 'GET',
                    });
                    document.querySelector('.editus-search-results').innerHTML = await response.text();
                    document.querySelector('.editus-search__input-wrap').classList.add('editus-search__input-wrap_show');
                } catch (error) {
                    console.error('Ошибка:', error);
                }
            }
        };

        window.addEventListener('click', function(e){
            if (document.querySelector('.editus-search__input').contains(e.target)){
                // Clicked in search input
                document.querySelector('.editus-search__input-wrap').classList.add('editus-search__input-wrap_show');
            } else{
                // Clicked outside search input
                document.querySelector('.editus-search__input-wrap').classList.remove('editus-search__input-wrap_show');
            }
        });
    }

    if (document.querySelector('.lozad')) {
        const observer = lozad(); // lazy loads elements with default selector as '.lozad'
        observer.observe();
    }
});
